body {
  background-color: white;
}

#wholeAboutDiv {
  border-top: 5px solid;
  border-color: rgb(195, 7, 63);
  padding-bottom: 40px;
  background-color: white;
  overflow: hidden;
}

#aboutSummany {
  width: 100%;
  display: inline-block;
}

#fiserv {
  color: rgb(255, 75, 0);
}

#aboutTitle {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 4vh;
  z-index: -2;
  color: rgb(26, 26, 29);
}

@media screen and (min-width: 750px) {
  #aboutDiv {
    width: 60%;
    margin: auto;
  }

  #aboutText {
    width: 100%;
    font-size: 2.5vh;
    color: rgb(26, 26, 29);
  }

  #aboutPic {
    width: 25vh;
    margin-top: 7px;
    margin-right: 20px;
    padding-bottom: 0px;
    float: left;
    border:5px solid;
    border-color: rgb(195, 7, 63);
  }
}

@media screen and (max-width: 750px) {
  #aboutDiv {
    width: 80%;
    margin: auto;
  }

  #aboutPic {
    width: 15rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    border:5px solid;
    border-color: rgb(195, 7, 63);
  }

  #aboutTitle {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 8vw;
    z-index: -2;
    color: rgb(26, 26, 29);
  }

  #aboutText {
    margin: auto;
    width: 100%;
    font-size: 5vw;
    text-align: center;
    color: rgb(26, 26, 29);
  }
}
