#wholePortfolioDiv {
  background-color: white;
  border-top: 5px solid;
  border-color: rgb(195, 7, 63);
  padding-bottom: 40px;
}

#PortfolioSummary {
  width: 100%;
  display: inline-block;
}

@media screen and (min-width: 750px) {
  #PortfolioDiv {
    width: 60%;
    margin: auto;
    padding-bottom: 30px;
  }

  #PortfolioText {
    width: 100%;
    font-size: 2.5vh;
    color: rgb(26, 26, 29);
  }

  #PortfolioBTNs {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .gallery-image img {
    margin: auto;
    height: 17.5vw;
    width: 17.5vw;
    transform: scale(1);
    transition: transform 0.4s ease;
  }

  .transparent-box {
    height: 17.5vw;
    width: 17.5vw;
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    transition: background-color 0.3s ease;
  }

  .caption {
    margin: auto;
    margin-top: 5vw;
    opacity: 0;
    font-size: 1.9vw;
    color: white;
    font-weight: bold;
    text-shadow: 0 0 20px black, 0 0 20px black, 0 0 20px black, 0 0 20px black;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
}

@media screen and (max-width: 750px) {
  #PortfolioDiv {
    width: 80%;
    margin: auto;
    padding-bottom: 30px;
  }

  #PortfolioTitle {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 8vw;
    z-index: -2;
    color: rgb(26, 26, 29);
  }

  #PortfolioText {
    margin: auto;
    width: 100%;
    font-size: 5vw;
    text-align: center;
    color: rgb(26, 26, 29);
  }

  #PortfolioBTNs {
    display: block;
    width: 50%;
    align-items: center;
    text-align: center;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .button {
    border-radius: 5px;
    background: linear-gradient(90deg, rgb(195, 7, 63), #ff2a55);
    border: none;
    color: #ffffff;
    font-size: 5vw;
    padding: 20px;
    width: 8vw;
    transition: all 0.5s;
    cursor: pointer;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
  }

  .gallery-image img {
    margin: auto;
    height: 280px;
    width: 280px;
    transform: scale(1);
    transition: transform 0.4s ease;
  }

  .transparent-box {
    height: 280px;
    width: 280px;
    background-color: rgba(0, 0, 0, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    transition: background-color 0.3s ease;
  }

  .caption {
    margin: auto;
    margin-top: 70px;
    opacity: 1;
    font-size: 8vw;
    color: white;
    font-weight: bold;
    text-shadow: 0 0 20px black, 0 0 20px black, 0 0 20px black, 0 0 20px black;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }
}

#portBTN {
  width: 100%;
}

.heading {
  text-align: center;
  font-size: 2em;
  letter-spacing: 1px;
  padding: 40px;
  color: white;
}

.gallery-image {
  display: inline-block;
  margin: auto;
  padding-top: 30px;
  text-align: center;
  width: 100%;
}

.img-box {
  box-sizing: content-box;
  margin: 10px;
  overflow: hidden;
  display: inline-block;
  color: white;
  position: relative;
  background-color: white;
}

.img-box:hover img {
  transform: scale(1.1);
}

.img-box:hover .transparent-box {
  background-color: rgba(0, 0, 0, 0.8);
}

.img-box:hover .caption {
  transform: translateY(-20px);
  opacity: 1;
}

.img-box:hover {
  cursor: pointer;
}

.caption > p:nth-child(2) {
  font-size: 0.8em;
}

.opacity-low {
  opacity: 0.5;
}
