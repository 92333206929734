body {
  background-color: white;
}

#wholeProjectEvenCS {
  padding-bottom: 0px;
  background-color: rgb(26, 26, 29);
  border-top: 5px solid;
  border-bottom: 5px solid;
  border-color: rgb(195, 7, 63);
  overflow: hidden;
}

#ProjectEvenCSSummany {
  width: 100%;
  display: inline-block;
}

#ProjectEvenCSTitle {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 4vh;
  z-index: -2;
  color: white;
}

#vidDiv {
  height: 100%;
  display: flex;
}

#projectBTN {
  width: 100%;
  margin-bottom: 0px;
}

@media screen and (min-width: 750px) {
  #ProjectEvenCS {
    width: 60%;
    margin: auto;
  }

  #ProjectEvenCSText {
    width: 100%;
    text-align: center;
    font-size: 2.5vh;
    color: white;
  }

  #ProjectEvenCSVid {
    height: 30vw;
    margin: auto;
    border: 5px solid;
    border-color: rgb(195, 7, 63);
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 750px) {
  #ProjectEvenCS {
    width: 80%;
    margin: auto;
  }

  #ProjectEvenCSVid {
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  #ProjectEvenCSTitle {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 8vw;
    z-index: -2;
    color: white;
  }

  #ProjectEvenCSText {
    margin: auto;
    width: 100%;
    font-size: 5vw;
    text-align: center;
    color: white;
  }
}
