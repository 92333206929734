#wholeQualDiv {
  background-color: rgb(26, 26, 29);
  border-top: 5px solid;
  border-color: rgb(195, 7, 63);
  padding-bottom: 40px;
}

#qualificationSummary {
  width: 100%;
  display: inline-block;
}

#qualificationTitle {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 4vh;
  z-index: -2;
  color: white;
}

#qualificationText {
  width: 100%;
  font-size: 2.5vh;
  color: white;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  align-items: center;
  z-index: 1; /* Sit on top */
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  position: fixed;
  background-color: #fefefe;
  border: 1px solid #888;
  top: 10%;
  left: 10%;
  width: 80%; /* Could be more or less, depending on screen size */
  height: 80%;
}

/* The Close Button */
.close {
  color: rgb(0, 0, 0);
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (min-width: 750px) {
  #QualificationDiv {
    width: 60%;
    margin: auto;
    padding-bottom: 30px;
  }

  #qualificationText {
    width: 100%;
    font-size: 2.5vh;
  }

  #qualBTNs {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }

  .button {
    border-radius: 5px;
    background: linear-gradient(90deg, rgb(195, 7, 63), #ff2a55);
    border: none;
    color: #ffffff;
    text-align: center;
    font-size: 2.4vh;
    padding: 20px;
    width: 21vh;
    transition: all 0.5s;
    cursor: pointer;
    margin: 5px;
  }
}

@media screen and (max-width: 750px) {
  #QualificationDiv {
    width: 80%;
    margin: auto;
    padding-bottom: 30px;
  }

  #qualificationTitle {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 8vw;
    z-index: -2;
    color: white;
  }

  #qualificationText {
    margin: auto;
    width: 100%;
    font-size: 5vw;
    text-align: center;
  }

  #qualBTNs {
    display: block;
    width: 100%;
    align-items: center;
    text-align: center;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  .button {
    border-radius: 5px;
    background: linear-gradient(90deg, rgb(195, 7, 63), #ff2a55);
    border: none;
    color: #ffffff;
    font-size: 5vw;
    padding: 20px;
    width: 8vw;
    transition: all 0.5s;
    cursor: pointer;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
  }
}
