nav {
  background: #000000;
}

.nav-container {
  border-bottom: 5px solid;
  border-color: transparent;
  background: linear-gradient(90deg, #fc466b, rgb(195, 7, 63), #fc466b);
  background-clip: border;
  position: fixed;
  width: 100%;
  z-index: 3;
}

.navLogoDiv {
  margin: auto;
  display: inline-flex;
  margin-top: 10px;
  height: 48px;
}

#websiteLogo {
  height: 90%;
  margin-right: 15px;
}

.gradient-text {
  margin-top: 5px;
  font-family: Trebuchet MS, Arial, Helvetica;
  color: white;
  background-clip: text;
  text-decoration: none;
  font-size: 20px;
  position: relative;
}

.gradient-text:before {
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  content: "";
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  margin-bottom: 10px;
}

.gradient-text:hover:before {
  visibility: visible;
  width: 100%;
}

.gradient-text:hover {
  margin-top: 5px;
  font-family: Trebuchet MS, Arial, Helvetica;
  color: white;
  text-decoration: none;
}

button {
  cursor: pointer;
}

@media screen and (min-width: 750px) {
  #logoText {
    margin-right: 15px;
    margin-left: 15px;
  }

  #websiteLogo {
    transform: translateY(-1px);
  }

  #burgerBTN {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  #logoText {
    display: none;
  }

  #websiteLogo {
    margin: auto;
    transform: translateY(-3px);
  }

  #burgerBTN {
    position: fixed;
    color: white;
    background-color: transparent;
    border: 0px;
    outline: none;
  }

  #burgerIcon {
    font-size: 30px;
  }
}
