#wholeFooterDiv {
  background-color: rgb(26, 26, 29);
  border-top: 5px solid;
  border-color: rgb(195, 7, 63);
  padding-bottom: 200px;
}

#footerTitleDiv {
  display: inline-flex;
  margin: auto;
  float: left;
  margin-top: 50px;
}

#footerBody {
  display: inline-block;
  width: 100%;
  margin: auto;
  margin-top: 50px;
}

#inlineFooter {
  display: flex;
  margin-bottom: 20px;
}

#footerExLink {
  color: rgb(195, 7, 63);
}

@media screen and (min-width: 750px) {
  #FooterDiv {
    width: 60%;
    margin: auto;
    padding-bottom: 30px;
  }

  #footerLogo {
    width: 4rem;
    height: 4rem;
    margin: auto;
    margin-right: 20px;
  }

  #FooterTitle {
    color: white;
    margin: auto;
    font-size: 6vh;
  }

  #FooterText {
    font-size: 2vh;
    color: white;
    margin-top: 2px;
    padding: 0px;
    width: 70%;
  }

  #footerLink {
    width: fit-content;
    font-size: 2vh;
    color: white;
    margin: 0px;
    padding: 0px;
  }
}

@media screen and (max-width: 750px) {
  #FooterDiv {
    width: 80%;
    margin: auto;
    padding-bottom: 30px;
  }

  #footerLogo {
    width: 10vw;
    height: 10vw;
    margin: auto;
    margin-right: 20px;
  }

  #FooterTitle {
    color: white;
    margin: auto;
    font-size: 6vw;
  }

  #FooterText {
    font-size: 3vw;
    color: white;
    margin-top: 1px;
    padding: 0px;
    width: 70%;
  }

  #footerLink {
    width: fit-content;
    font-size: 3vw;
    color: white;
    margin: 0px;
    padding: 0px;
  }
}
