body {
  background-color: white;
}

#wholeProjectOddCS {
  padding-bottom: 0px;
  overflow: hidden;
  background-color: white;
}

#ProjectOddCSSummany {
  width: 100%;
  display: inline-block;
}

#ProjectOddCSTitle {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
  font-size: 4vh;
  z-index: -2;
  color: rgb(26, 26, 29);
}

#vidDiv {
  width: 100%;
  display: flex;
}

#projectBTN {
  width: 100%;
  margin-bottom: 0px;
}

@media screen and (min-width: 750px) {
  #ProjectOddCS {
    width: 60%;
    margin: auto;
  }

  #ProjectOddCSText {
    width: 100%;
    text-align: center;
    font-size: 2.5vh;
    color: rgb(26, 26, 29);
  }

  #ProjectOddCSVid {
    height: 30vw;
    margin: auto;
    border: 5px solid;
    border-color: rgb(195, 7, 63);
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 750px) {
  #ProjectOddCS {
    width: 80%;
    margin: auto;
  }

  #ProjectOddCSVid {
    margin: auto;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  #ProjectOddCSTitle {
    text-align: center;
    margin-top: 40px;
    margin-bottom: 30px;
    font-size: 8vw;
    z-index: -2;
    color: rgb(26, 26, 29);
  }

  #ProjectOddCSText {
    margin: auto;
    width: 100%;
    font-size: 5vw;
    text-align: center;
    color: rgb(26, 26, 29);
  }
}
