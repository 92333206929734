/* Cool infinite background scrolling animation.
 * Twitter: @kootoopas
 */
/* Exo thin font from Google. */
@import url(https://fonts.googleapis.com/css?family=Exo:100);
/* Background data (Original source: https://subtlepatterns.com/grid-me/) */
/* Animations */
@-webkit-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@-moz-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@-o-keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@keyframes bg-scrolling-reverse {
  100% {
    background-position: 50px 50px;
  }
}
@-webkit-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
@-moz-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
@-o-keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
@keyframes bg-scrolling {
  0% {
    background-position: 50px 50px;
  }
}
/* Main styles */
#wholePortHead {
  background: linear-gradient(
    180deg,
    rgb(0, 0, 0),
    rgb(48, 48, 48),
    #b3b3b3,
    #ffffff
  );
  padding-top: 80px;
  width: 100%;
  height: 37rem;
  z-index: -1;
}

/* ALL COLORS ARE WRITTEN INVERTED! */

#header {
  display: block;
  position: absolute;
  top: 150px;
  text-align: center;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

#namePort {
  color: rgb(195, 7, 63);
  font-size: 8vh;
  padding-bottom: 30px;
}

#titlePort {
  color: white;
  font-size: 4vh;
  padding-bottom: 30px;
  text-shadow: -1px -1px 8px rgb(26, 26, 29), 1px -1px 8px rgb(26, 26, 29),
    -1px 1px 8px rgb(26, 26, 29), 1px 1px 8px rgb(26, 26, 29);
}

#quotePort {
  color: white;
  font-size: 4vh;
  padding-bottom: 30px;
  text-shadow: -1px -1px 8px rgb(26, 26, 29), 1px -1px 8px rgb(26, 26, 29),
    -1px 1px 8px rgb(26, 26, 29), 1px 1px 8px rgb(26, 26, 29);
}

#authorPort {
  color: rgb(26, 26, 29);
  font-size: 4vh;
  padding-bottom: 30px;
  float: right;
  margin-right: 12%;
}

@media screen and (max-width: 750px) {
  #header {
    display: block;
    position: absolute;
    top: 120px;
    text-align: center;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }

  #namePort {
    color: rgb(195, 7, 63);
    font-size: 10vw;
    padding-bottom: 30px;
  }
  
  #titlePort {
    color: white;
    font-size: 8vw;
    padding-bottom: 30px;
    text-shadow: -1px -1px 8px rgb(26, 26, 29), 1px -1px 8px rgb(26, 26, 29),
      -1px 1px 8px rgb(26, 26, 29), 1px 1px 8px rgb(26, 26, 29);
  }
  
  #quotePort {
    color: white;
    font-size: 6vw;
    padding-bottom: 30px;
    text-shadow: -1px -1px 8px rgb(26, 26, 29), 1px -1px 8px rgb(26, 26, 29),
      -1px 1px 8px rgb(26, 26, 29), 1px 1px 8px rgb(26, 26, 29);
  }
  
  #authorPort {
    color: rgb(26, 26, 29);
    font-size: 6vw;
    padding-bottom: 30px;
    float: right;
    margin-right: 12%;
  }
}
